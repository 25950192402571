import { Menu } from "@headlessui/react";
import { useState } from "react";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Container } from "@mui/material";

/*function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
*/
export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  /*const handlclick=()=>{
      setIsOpen(!isOpen);
    }*/
  const nav: Array<Object> = [
    {id:1, name: "Acceuil", url: "/" },
    { id:2,name: "Service", url: "/#service" },

    { id:3,name: "Portofolio", url: "/forum" },
    { id:4,name: "Communaute", url: "/forum" },
  ];

  /*useEffect(()=>{
  const data=AuthService.getCurrentUser();
    

})*/
  return (
    <div className="bg-white shadow-sm fixed top-0 left-0 right-0 z-10 ">
      <Container>
        <div className=" py-2 mx-auto  md:max-w-full lg:max-w-screen-xl px-2 sm:px-8 md:px-20 lg:px-26 xl:px-30">
          <div className="relative flex items-center justify-between">
            <Link
              to={"/"}
              aria-label="Company"
              title="Company"
              className="inline-flex items-center"
            >
              {/*<img src="/images/x-brainlabs.png"  className="h-14 w-14"/>*/}
              <span className="ml-0 text-sm font-medium tracking-wide text-black  uppercase">
                x<span className="text-black">-brainlabs</span>
              </span>
            </Link>
            <ul className="flex items-center hidden space-x-8 lg:flex">
              {nav.map((e: any, key) => {
                return (
                  <li key={e.id}>
                    <HashLink
                      smooth
                      to={e.url}
                      className="font-bolder  text-md tracking-wide text-black transition-colors duration-200 hover:text-[#22d3ee]"
                    >
                      {e.name}
                    </HashLink>
                  </li>
                );
              })}
            </ul>

            <ul className="flex items-center  hidden space-x-7 lg:flex">
              <li className="">
                <HashLink
                  to={"/#contact"}
                  className="inline-flex text-lg rounded-3xl  items-center justify-center h-12 px-4 font-medium tracking-wide  transition duration-200  bg-sc text-white hover:bg-white hover:border-blue-100 border hover:text-gray-500 hover:border-slate-100  focus:shadow-outline focus:outline-none"
                >
                  contact
                </HashLink>
              </li>
            </ul>
            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full ">
                  <div className="p-5 bg-white  border rounded shadow-sm">
                    <div className="flex items-center justify-between mb-4">
                      <div className="text-center">
                        <Link
                          to={"/"}
                          aria-label="Company"
                          title="Company"
                          className="inline-flex items-center justify-center "
                        >
                          {/*<img src="/images/l1.ico"  className="h-12 w-12 mx-auto"/>*/}
                          <span className="ml-0   text-sm font-medium tracking-wide text-black  uppercase">
                            x<span className="text-pc">-brainlabs</span>
                          </span>
                        </Link>
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg
                            className="w-5 text-gray-600"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <Menu>
                      <nav>
                        <ul className=" space-y-4">
                          {nav.map((e: any, key) => {
                            return (
                              <li key={e.id}>
                                <HashLink
                                  smooth
                                  to={e.url}
                                  className="flex justify-center font-bolder text-lg  tracking-wide text-gray-500 transition-colors duration-200 hover:text-[#24c3c5] "
                                >
                                  {e.name}
                                </HashLink>
                              </li>
                            );
                          })}

                          <li>
                            <HashLink
                              to={"/#contact"}
                              className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide bg-sc text-white rounded-3xl transition duration-200 rounded shadow-md    hover:bg-white  focus:shadow-outline focus:outline-none"
                              aria-label="Sign up"
                              title="Sign up"
                            >
                              Contact
                            </HashLink>
                          </li>
                        </ul>
                      </nav>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
