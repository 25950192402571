import { Box } from "@mui/material";
import { Container } from "@mui/material";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link } from "react-router-dom";


const Success=()=>{
    return(<>
    <Navbar/>
    <Container>
      <Box sx={{display:'flex',
      justifyContent:'center',
      alignItems:'center',
      height:'60vh'}} >

          <div className="max-w-md rounded-md border py-8 px-32">
          <Box  className=' mx-auto  h-24 w-24    p-2 '>
          
          <img alt="mail"  className="  w-100   object-fit  rounded-lg object-center " src="/images/mail.png" />
         </Box>
             <h1 className="capitalize"> Envoyer avec succès</h1>
              <Box sx={{display:'flex',
      justifyContent:'center',
      alignItems:'center',
      }}>
              <Link
              to={'/'}
              className=" rounded-md bg-sc px-3 py-1 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Retour
            </Link>
              </Box>
             </div>
           
      </Box>
      </Container>

      <Footer/>
    </>)
}


export default Success;