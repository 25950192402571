import { Box } from "@mui/material";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { motion } from "framer-motion";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

const Contact = () => {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [progress, setProgress] = useState(false);
  //const [success, setSuccess] = useState<any>(false);
  const navigate = useNavigate();

  const sendEmail = (e: any) => {
    e.preventDefault();

    setProgress(true);
    emailjs
      .sendForm(
        "service_ovcb1qv",
        "template_bm04m6f",
        e.target,
        "izJeXXbbnkwbI76_C"
      )
      .then(
        (result: any) => {
          setProgress(false);
          navigate('/mailsend');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Box className="pt-16" id="contact">
        {" "}
        <h1 className="text-center text-2xl font-bold   pb-2 " id="contact">
          Contact
        </h1>
        <div className="grid max-w-screen-xl grid-cols-1 gap-8  px-4 py-4 mx-auto rounded-lg md:grid-cols-2 md:px-20 lg:px-26 xl:px-30 ">
          <div className="flex flex-col justify-between m-0 md:m-16 p-4">
            <div className="flex mb-2">
              <motion.div
                whileInView={{ scale: [1.0, 1.1, 1] }}
                initial={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 4 }}
                animate={{ y: 0 }}
              >
                <div className="flex-4 w-20 rounded-xl  shadow-xl text-center p-2 border border-gray-200 shadow-blue-500/10">
                  <FaEnvelope className="mx-auto w-10 h-10 text-pc" />
                </div>
              </motion.div>
              <div className="flex-2 px-4 py-4 ">
                <span className="text-sm text-gray-500">
                  contact@x-brainlabs.com
                </span>
              </div>
            </div>
            <div className="flex mb-2">
              <motion.div
                whileInView={{ scale: [1.0, 1.1, 1] }}
                initial={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 4 }}
                animate={{ y: 0 }}
              >
                <div className="flex-4 w-20 rounded-xl  shadow-xl text-center p-2 border border-gray-200 shadow-blue-500/10">
                  <FaPhoneAlt className="mx-auto w-10 h-10 text-red-500" />
                </div>
              </motion.div>
              <div className="flex-2 px-4 py-4 ">
                <span className="text-sm text-gray-500">+243 850 543 169</span>
              </div>
            </div>
            <div className="flex mb-2">
              <motion.div
                whileInView={{ scale: [1.0, 1.1, 1] }}
                initial={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 4 }}
                animate={{ y: 0 }}
              >
                <div className="flex-4 w-20 rounded-xl  shadow-xl text-center p-2 border border-gray-200 shadow-blue-500/10">
                  <FaMapMarkerAlt className="mx-auto w-10 h-10 text-yellow-500" />
                </div>
              </motion.div>
              <div className="flex-1 px-4 py-4">
                <span className="text-sm text-gray-500">RDC GOMA</span>
              </div>
            </div>
          </div>
          <form
            className="space-y-0  sm:px-4 md:px-8 lg:px-16"
            onSubmit={sendEmail}
          >
            <div>
              <label htmlFor="name" className="text-sm text-gray-500">
                Entreprise
              </label>
              <input
               id="name"
                type="text"
                placeholder="votre entreprise ou votre nom"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                className="border  w-full p-3 rounded bg-gray-50 border-blue-100 focus:outline-none focus:border-sky-200"
              />
            </div>
           
            <div>
              <label htmlFor="email" className="text-sm text-gray-500">
                Email
              </label>
              <input
			  id="email"
                name="email"
                type="email"
                placeholder="votre mail"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="border  w-full p-3 rounded bg-gray-50 border-blue-100 focus:outline-none focus:border-sky-200"
              />
            </div>
            <div>
              <label htmlFor="message" className="text-sm text-gray-500">
                Description Du projet
              </label>
              <textarea
			  id="message"
                name="message"
                placeholder="votre message"
                rows={3}
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)}
                className="border  w-full p-3 rounded bg-gray-50 border-blue-100 focus:outline-none focus:border-sky-200"
              ></textarea>
            </div>
            <div className="mb-2">

<label htmlFor="enterprise" className="text-sm text-gray-500">
  Votre Budjet
</label>
<select name="amount" value={amount} onChange={(e)=>setAmount(e.target.value)} className="border  w-full p-3 rounded bg-gray-50 border-blue-100 focus:outline-none focus:border-sky-200">

    <option  value="< 700$ ">{"< 700$"}</option>
    <option value="< 1200$ ">{"< 1200$"}</option>
    <option value="> 2000$ "> {"> 2000$"}</option>
   
</select>

</div>

            <button
              type="submit"
              className="w-full border p-3  text-sm font-bold tracki  rounded bg-sc text-white mt-2"
            >
              {progress ?  "Envois en cours ..." : "Envoyer"}
             
            </button>
          </form>
        </div>
      </Box>
    </>
  );
};

export default Contact;
