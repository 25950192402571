import { Navbar } from "../Navbar";
import Building from "../error/Building";

import { useState } from "react";

const Forum = () => {
  const [activ, setactiv] = useState(false);
  setTimeout(() => {
    setactiv(true);
  }, 3000);

  return (
    <>
      <Navbar />

      {
        activ ? (
          <Building />
        ) : (
          <div className="glob-loading">
            <h1 className="para-x">X</h1>
          </div>
        )

        /* <Box marginTop={10} className="bg-[#fbfbfc]" marginBottom={0}   >
<Container>
   <Grid container spacing={4} >
          <Grid item xs={12} md={3} sm={4} lg={3} sx={{display:{xs:'none',md:'block',sm:'block'}}}>
            <Aside/>
          </Grid>

          <Grid item xs={12} md={6} sm={8} lg={6}>
            <Box >
              <Articles/>
              </Box>
          </Grid>

          <Grid item xs={12} md={3} sm={6} lg={3} sx={{display:{xs:'none',md:'block',sm:'none'}}}>
              community
          </Grid>
   </Grid>
   </Container>
   </Box>   */
      }
    </>
  );
};

export default Forum;
