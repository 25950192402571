

const  Team = () => {        
    return (
        <section className="py-10 bg-white sm:py-8 lg:py-16">
    <div className="px-4 mx-auto max-w-7xl  md:px-10 lg:px-26 xl:px-32">
        <div className="text-center">
            <h2 className="text-2xl font-bold  font-pj">Equipe</h2>
        </div>

        <div className="grid max-w-6xl grid-cols-1 px-2 mx-auto mt-12 text-center sm:px-0 sm:grid-cols-2 md:mt-20 gap-x-2 md:grid-cols-4 gap-y-12 lg:gap-x-2 xl:gap-x-lg">
            <div className='shadow-lg rounded-lg w-100 pb-2 shadow-gray-500/10 '>
                <img className="object-cover w-full  h-80 lg:h-60 md:h-60   mx-auto rounded-lg lg:w-full  filter" src="/images/schad.jpg" alt="" />
                <p className="sm:mt-2 text-lg font-bold text-gray-900  sm:mt-8 ">Schadrack kabinzo</p>
                <p className=" text-base font-normal text-gray-600 font-pj">CEO & Co-founder</p>
                <p className="text-base font-normal text-gray-600 font-pj">Devops & Back-end</p>
            </div>

            <div className='shadow-lg rounded-lg w-100 pb-2 shadow-gray-500/10 '>
                <img className="object-cover w-full  h-80 lg:h-60 md:h-60  mx-auto rounded-lg lg:w-full  filter" src="/images/sibam.png" alt="" />
                <p className="sm:mt-2  text-lg font-bold text-gray-900  sm:mt-8 ">Simplice Sibamtaki</p>
                <p className=" text-base font-normal text-gray-600 font-pj">COO </p>
                <p className="text-base font-normal text-gray-600 font-pj">Leader</p>
            </div>
            <div className='shadow-lg rounded-lg w-100 pb-2 shadow-gray-500/10 '>
                <img className="object-cover w-full h-80 lg:h-60 md:h-60  mx-auto rounded-lg lg:w-100  filter" src="/images/davcto.jpg" alt="" />
                <p className="sm:mt-2  text-lg font-bold text-gray-900  sm:mt-8 ">David kabinzo</p>
                <p className=" text-base font-normal text-gray-600 font-pj"></p>
                <p className="text-base font-normal text-gray-600 font-pj">front-developper</p>
            </div>
            <div className='shadow-lg rounded-lg w-100 pb-2 shadow-gray-500/10 '>
                <img className="object-cover w-full  h-80 lg:h-60 md:h-60  mx-auto rounded-lg lg:w-full  filter" src="/images/davids.png" alt="" />
                <p className="sm:mt-2  text-lg font-bold text-gray-900  sm:mt-8 ">David Nzoli</p>
                <p className=" text-base font-normal text-gray-600 font-pj"></p>
                <p className="text-base font-normal text-gray-600 font-pj">front-developper</p>
            </div>

           

           

        </div>

       

       
    </div>
</section>

    )
}
export default Team;