
import { Container } from "@mui/material";

const About=()=>{
    return(
    <>
    <Container>
<section className="text-gray-600 body-font  ">

  <div className="   mx-auto px-4 sm:px-8 md:px-20 lg:px-26 xl:px-30">
  <h1 className="text-center text-2xl font-bold  pb-8" id="about">{/*appropos*/}</h1>
    <div className="flex flex-wrap -mx-4 -mb-10 text-center">
      <div className="sm:w-full md:w-full mb-10 px-4">
      <div className="bg-slate-50 shadow-xl border border-slate-50  rounded overflow-hidden p-1  shadow-blue-500/5 p-1">
        <div className="grid max-w-screen-xl grid-cols-1 gap-8  px-4 py-8 mx-auto rounded-lg md:grid-cols-2 md:px-20 lg:px-26 xl:px-30 ">
        <img src="/images/icon3.png" alt="" width={"90%"} className="mx-auto  h-100 md:h-100 pb-6" />
  <div className="py-4 px-1  mt-2">
    {/*<div className="font-bold text-xl mb-2">Who we are ?</div>*/}
    <p className="text-lg md:leading-8  sm:leading-1 sm:text-justify text-gray-500 md:py-4  ">
    Une entreprise spécialisée dans le développement des <span className="text-blue-500">logiciels</span> et apportant des solutions technologiques aux entreprises, aux jeunes etc.   </p>
  </div>
 
  </div>
</div>
      </div>
      <div className="sm:w-full md:w-full  mb-10 px-4 mt-4 ">
      <div className="w-100 bg-white shadow-blue-500/5 shadow-xl border border-slate-50  rounded overflow-hidden p-1  ">
      <div className="grid max-w-screen-xl grid-cols-1 gap-8  px-4 py-8 mx-auto rounded-lg md:grid-cols-2 md:px-20 lg:px-26 xl:px-30 ">
      <img src="/images/icon4.png" alt="" width={"80%"} className="mx-auto  h-100 md:h-100 pb-6" />
  <div className=" py-4  px-1">
    {/*<div className="font-bold text-xl mb-2">What we do?</div>*/}
    <p className="text-lg sm:leading-1 sm:text-justify md:leading-8  text-gray-500 md:py-4  text-gray-500  mt-2 py-4">
       Nous <span className="text-yellow-500">concevons</span> et  <span className="text-blue-500">développons</span> des applications logicielles et  des produits sur mesure dans un délai précis. Nos services sont appréciés par tous, car nous dépassons toujours les attentes de nos clients.
    </p>
  </div>
 </div>
</div>
      </div>
    </div>
  </div>
</section>
{/** video description 
<section className="text-gray-600 body-font ">

<div className="py-24 mx-auto px-4 sm:px-8 md:px-20 lg:px-26 xl:px-30">
 
    <div className="flex flex-wrap flex justify-center -mx-4 -mb-10 text-center">
      <div className="sm:w-1/2 mb-10 px-4">
      <div className="w-100 rounded overflow-hidden bg-white  p-1">
  <div className=" py-4 ">
         <img src="/images/x-affiche.jpg" className="w-full  object-center" /> 
  </div>
 
</div>
      </div>
      <div className="sm:w-1/2 mb-10 px-4  ">
      <div className="w-100 bg-white   rounded overflow-hidden p-1">
  <div className=" py-4 xl:py-32 md:py-16 ">
    <div className="font-bold text-xl mb-2">Short description </div>
    <p className="text-lg  leading-8 text-second-color text-justify">
     We develop Software application  , and making design  to your demand in a specific time, and the service we make it's appreciated by any one,cause we go over of your demand
    </p>
  </div>
 
</div>
      </div>
    </div>
  </div>
</section>
*/}
</Container>
    </>
    );
}

export default About;