import * as React from 'react';
import Box from '@mui/material/Box';


const Features=()=> {
 




  return (
    <Box sx={{ width: '100%', typography: 'body1' }} className="pt-4  pb-16    mx-auto sm:w-full  md:max-w-full lg:max-w-screen-xl px-2 sm:px-0 md:px-20 lg:px-26 xl:px-32  ">


        <section className="    text-black  p-4  ">
	<div className=" mx-auto space-y-12  " >
		<div  className="flex flex-col  overflow-hidden rounded-md shadow-lg shadow-cyan-500/5 lg:flex-row md:border-r-4 border-yellow-500">
			<img src="/images/ui.jpg" alt="" className="md:h-80 sm:h-60  bg-gray-500 object-cover aspect-video w-100" />
			<div className="flex flex-col justify-center flex-1 p-6 bg-[#ffffff]">
				<span className="text-xs uppercase text-gray-400">Design</span>
				<h3 className="text-2xl font-bold ">UX/UI </h3>
				<p className="my-6 text-gray-500 text-lg">
				un design de haute qualité basé sur le principe de l'expérience utilisateur. Nous affirmons nos designs avec les utilisateurs pour nous assurer qu'ils sont faciles à utiliser et à comprendre.
        </p>
				<button type="button" className="self-start"></button>
			</div>
		</div>
		<div className="flex flex-col overflow-hidden rounded-md shadow-lg shadow-cyan-500/5 lg:flex-row-reverse md:border-s-4 border-green-500">
			<img src="/images/code.jpg" alt="" className="md:h-80 sm:h-60  bg-gray-500 aspect-video object-cover" />
			<div className="flex flex-col justify-center flex-1 p-6 bg-[#ffffff] ">
				<span className="text-xs uppercase text-gray-400">CODE</span>
				<h3 className="text-2xl font-bold ">Le développement logiciel</h3>
				<p className="my-6 text-gray-500 text-lg">
				Nous développons des logiciels en fonction de vos besoins, nous prenons en compte vos demandes et nous livrons le produit plus rapidement, en fournissant une analyse approfondie d'un logiciel.
          </p>
				<button type="button" className="self-start"></button>
			</div>
		</div>
		<div className="flex flex-col overflow-hidden rounded-md shadow-cyan-500/5 shadow-lg lg:flex-row md:border-r-4 border-yellow-500">
			<img src="/images/formation.jpg" alt="" className="md:h-80 sm:h-60  bg-gray-500 object-cover aspect-video" />
			<div className="flex flex-col justify-center flex-1 p-6 bg-[#ffffff] ">
				<span className="text-xs uppercase text-gray-400">apprendre</span>
				<h3 className="text-2xl font-bold ">Xacademy</h3>
				<p className="my-6 text-gray-500 text-lg">  Votre apprentissage devient facile pour vous, trouvez le cours que vous aimez, nous enseignons comment coder un site Web, une application mobile, le design UX/UI et beaucoup plus, y compris l'intelligence artificielle.</p>
				<button type="button" className="self-start"></button>
			</div>
		</div>
    <div className="flex flex-col overflow-hidden rounded-md shadow-cyan-500/5 shadow-lg lg:flex-row-reverse sm:border-s-2  md:border-s-4 border-green-500">
			<img src="/images/freelancing.png" alt="" className="md:h-80 sm:h-60  w-90  bg-gray-500 object-cover aspect-video " />
			<div className="flex flex-col justify-center flex-1 p-6 bg-[#ffffff] ">
				<span className="text-xs uppercase text-gray-400">Travail  distant</span>
				<h3 className="text-2xl font-bold ">Freelance</h3>
				<p className="my-6 text-gray-500 text-lg">
				Nous offront le service de freelance à toute entreprise ayant un projet, qu'il s'agit d'un projet de conception, de développement ou autre.
          </p>
				<button type="button" className="self-start"></button>
			</div>
		</div>
	</div>
</section>

    </Box>
  );
}


export default Features;