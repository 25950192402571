import { Container } from "@mui/material";




export const service:Array<Object>=[
    {id:1,designation:"UI/UX & Design",url:"/images/uiux.jpg",description:"We think! we design ,we implement,we make it easy for you",className:"h-full p-6 bg-3 rounded-lg  border-1 border-indigo-500 flex flex-col relative overflow-hidden",
    content:
      "We make design for applications as layout ,and we make other types of design to your demande,for that feel free to be in touch with us to start working with our expert in design"
    },
    {id:2,designation:"Software Development",url:"/images/uiux.jpg",description:"We make softaware for you",className:"h-full p-6 bg-2 rounded-lg  border-1 border-indigo-500 flex flex-col relative overflow-hidden"},
    {id:3,designation:"Xacademy",url:"/images/uiux.jpg",description:"learn stack",className:"h-full p-6 bg-3 rounded-lg  border-1 border-indigo-500 flex flex-col relative overflow-hidden"},
    {id:4,designation:"Freelancing",url:"/images/uiux.jpg",description:"We work  remotelly with enterprizes",className:"h-full p-6 bg-4 rounded-lg  border-1 border-indigo-500 flex flex-col relative overflow-hidden"},
];


const Service=()=>{    
    return(
    <>

<section id="service" className="  mb-3  mx-auto  md:max-w-full lg:max-w-screen-xl px-2 sm:px-8 md:px-20 lg:px-26 xl:px-32 ">
  <Container>
  <div
    className="  "
  >
    <div className=" mx-auto text-center">
      <h2 className="text-2xl  font-bold  font-bold ">Services</h2>

      <p className="mt-4 text-gray-500 leading-6">
      Nous proposons des services en fonction de vos besoins,<br/> et même plus que ce qui est mentionné ici.
      </p>
    </div>

    <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
      <div
        className="block rounded-xl border p-10 shadow-lg  transition border-blue-500/5  shadow-blue-500/5"
     
      >
        <div className="bg-gray-100  p-4 w-20 border rounded-xl border-md">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mx-auto">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
</svg></div>

        <h2 className="mt-4 text-xl font-bold ">UX/UI & DESIGN</h2>

        <p className="mt-1  text-gray-500">

Nous vous facilitons la tâche pour obtenir un bon design basé sur l'expérience utilisateur. Nous proposons une grande variété de designs, et le design spécifique dépendra de vous ou de vos besoins.
        </p>
      </div>

      <div
        className="block rounded-xl border border-blue-500/5 p-10 shadow-lg transition   shadow-blue-500/5"
       
      >
        <div className="bg-gray-100  p-4 w-20 border rounded-xl border-md">
       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mx-auto">
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
</svg></div>

        <h2 className="mt-4 text-xl font-bold ">Le développement logiciel</h2>

        <p className="mt-1  text-gray-500">
        Nous développons des logiciels pour répondre à vos besoins . Nous prenons en compte vos demandes et livrons le produit plus rapidement, en fournissant une bonne analyse du logiciel.
        </p>
      </div>

      <div
        className="block rounded-xl border  p-8 shadow-lg transition border-blue-500/5  shadow-blue-500/5"
      
      >
        <div className="bg-gray-100  p-4 w-20 border rounded-xl border-md">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mx-auto">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg></div>

        <h2 className="mt-4 text-xl font-bold ">Xacademy</h2>

        <p className="mt-1  text-gray-500">
         Nous proposons une variété de cours  qui vous permettent d'apprendre à votre rythme et de développer les compétences dont vous avez besoin pour réussir dans le domaine de la technologie et autre. 
        </p>
      </div>

      <div
        className="block rounded-xl   p-10 shadow-lg transition border border-blue-500/5 shadow-blue-500/5"
       
      >
        <div className="bg-gray-100  p-4 w-20 border rounded-xl border-md ">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mx-auto">
  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m0-3l-3-3m0 0l-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />
</svg>
</div>

        <h2 className="mt-4 text-xl font-bold ">Freelancing</h2>

        <p className="mt-1  text-gray-500">
        nous offrons une solution de freelance pour les entreprises de toutes tailles qui ont besoin d'aide pour réaliser leurs projets. Nous avons une équipe de freelances qualifiés et expérimentés dans un large éventail de domaines.
        </p>
      </div>


    </div>

   
  </div>
  </Container>
</section>

    </>
    );
}

export default Service;