import * as React from 'react';
import Box from '@mui/material/Box';

import { Container } from '@mui/material';


const Partnership=()=> {




  const datas:Array<Object>=[
    {"id":1,"name":"Html","url":'/images/3.png',"alt":'other'},

    
  ];
 


  return (
    <Box sx={{ width: '100%', typography: 'body1' }} className="pt-8 pb-16  ">
        <Container>
        <h1 className=" text-center  text-2xl font-bold title-font mb-2  pb-4">Nos Partenaires</h1>
        <div className="mb-3  mx-auto  md:max-w-full lg:max-w-screen-xl px-2 sm:px-8 md:px-20 lg:px-26 xl:px-32 ">
        <div className="mt-8   grid grid-cols-1 gap-4 md:grid-cols-4 lg:grid-cols-4 ">
       <ul>
      
        {datas.map((e:any,key)=>{
          return(
            
           <li key={e.id}>
         
          <Box  className=' mx-auto  h-24 w-24  m-4  p-2 '>
          
          <img alt={e.alt}  className="  w-100   object-fit  rounded-lg object-center " src={e.url} />
         </Box>
         </li>
          );
        })}
        </ul>
          
         </div>
         </div>
      </Container>
    </Box>
  );
}


export default Partnership;