import * as React from 'react';
import Box from '@mui/material/Box';

import { Container } from '@mui/material';


const Stack=()=> {
 

  const datas:Array<Object>=[
    {"name":"Html","url":'/images/html.png'},
    {"name":"Html","url":'/images/css.png'},
    {"name":"Html","url":'/images/Sass.png'},
    {"name":"javascript","url":'/images/js.png'},
    {"name":"React js","url":'/logo192.png'},
    {"name":"jenkiss","url":'/images/vite.png'},
    {"name":"Typescript","url":'/images/typescript.svg'},
    {"name":"jenkiss","url":'/images/php.png'},
    {"name":"Python","url":'/images/python.png'},
    {"name":"Postgres sql","url":'/images/postgres.png'},
    {"name":"flutter","url":'/images/flutter.png'},
    {"name":"docker","url":'/images/docker.png'},
    {"name":"jenkiss","url":'/images/jenkins.png'},
    {"name":"jenkiss","url":'/images/aws.png'},
    
  ];



  return (
    <Box sx={{ width: '100%', typography: 'body1' }} className="pt-4 pb-16  ">
        <Container>
        <h1 className=" text-center  text-2xl font-bold title-font mb-2  pb-4">Technologies</h1>
        <div className="mb-3  mx-auto  md:max-w-full lg:max-w-screen-xl px-2 sm:px-8 md:px-20 lg:px-26 xl:px-32 ">
        <div className="mt-8   grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-4 ">
       
      
        {datas.map((e:any,key)=>{
          return(
           <> 
    
          {/*<p className='text-center'>{e.name}</p>*/}
          <Box  className='shadow-lg mx-auto  h-24 w-24  m-4 bg-slate-50 p-2 '>
          
          <img alt="stack" className="  w-100   object-fit  rounded-lg object-center " src={e.url} />
         </Box>
         </>
          );
        })}

          
         </div>
         </div>
      </Container>
    </Box>
  );
}


export default Stack;