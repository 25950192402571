import { Box } from "@mui/material";

import { HashLink } from "react-router-hash-link";
import { Container } from "@mui/material";
const Header = () => {
  return (
    <Box>
      <div className="relative isolate px-4 pt-0 md:px-24 lg:px-26 xl:px-30 pb-4 bg-[#ffffff]">
        <Container>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-42">
            {/*<img src="/images/artificial.png"   className=" mx-auto logo"/>*/}

            <div className="text-center">
              <h1 className="text-4xl text-black font-bold capitalize tracking-tight text-gray-900 sm:text-xl md:text-6xl  text-centers">
                 Un monde meilleur avec <br />
                <span className="text-pc  ">la technologie</span>
              </h1>
              <p className="mt-6 text-xl  leading-8 text-gray-500 para-some-text">
                Des services de qualité sur votre disposition dans la tech
                world, la conception de logiciel et rendre vos idées réelles.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <HashLink
                  to={"/#about"}
                  className="   shadow-2xl shadow-blue-500/10  rounded-md  px-3.5 py-2.5 text-xl border border-black font-semibold  shadow-sm  hover:text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 btn-plus"
                >
                  Savoir +
                </HashLink>
              </div>
            </div>
          </div>
          {/* <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(40%+2rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#22d3ee] to-[#22d3ee] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>*/}
        </Container>
      </div>
    </Box>
  );
};

export default Header;
