import { Footer } from "./Footer";
import Navbar from "./Navbar"
import { service } from "./Service";
import {Box, Container, Grid, Paper, Typography} from '@mui/material';

const DetailService=()=>{
    
    const find:any=service.find((e:any)=>e.id=1);
     
    return (
    <>
    <Navbar/>
    
    <Box sx={{paddingTop:"6rem",paddingBottom:"1rem"}} className="bg-[#f9f9f9]">
        <Container >
        <Typography variant="h3" component="h3" className="about-title">
                    {find?.designation}
              </Typography>
        <Typography className="project-explanation">{find?.description} </Typography>
        <Grid container  spacing={2} >
      
        <Grid item sm={6} xs={12} md={6} lg={6} > 
          <Paper elevation={0} sx={{backgroundColor:"#f3f3f3"}}>
          <img src={find?.url} width="100%" height="80%" alt="img"/>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} md={6} lg={6}> 
        
        <p   className="text-lg text-second-color">
          {find?.content}
        </p>
      </Grid>
        </Grid>

       
        </Container>
     
    </Box>
     
    <Footer/>
    </>
    )
}

export default DetailService;