import React, { useState } from "react";

import "./App.css";
import Navbar from "./component/Navbar";
import Header from "./component/Header";
import About from "./component/About";
import Service from "./component/Service";

import Stack from "./component/Stack";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Notfound from "./component/error/Notfound";
import DetailService from "./component/DetailService";
import Forum from "./component/forum/Forum";

import Features from "./component/Features";
import Team from "./component/Team";
import Partnership from "./component/partnership";
import Success from "./component/error/Success";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <>
    <Helmet>
      <title>x-brainlabs</title>
    </Helmet>
      <Navbar />
      <Header />
      <About />
      <Features />
      <Service />

      <Stack />
      <Team />
      <Contact />
      <Partnership />
      <Footer />
    </>
  );
}

function App() {
  const [state, setState] = useState(false);
 

  setTimeout(() => {
    setState(true);
  }, 5000);

  
  return state ? (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Notfound />} />
          <Route path="/forum" element={<Forum />} />
          <Route path="/service/detail/:id" element={<DetailService />} />
          <Route path="/mailsend" element={<Success />} />
        </Routes>
      </Router>
    </>
  ) : (<>
    <div className="glob-loading  ">

      <h1 className="para-x ">X</h1>
     
      
    </div>
   </>
  );
}

export default App;
